<template>
  <div class="bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100">
    <div class="layout contain">
      <div class="grid grid-rows-[auto_1fr_auto] min-h-dvh">
        <header id="app-header" class="page-header">
          <div class="contain-override bg-primary-500 text-white">
            <UnityMenuTop class="contain-xl mb-2" />
          </div>
          <div class="contain-override border-b-4 border-primary-500">
            <UnityMenuMain class="contain-xl my-2" />
          </div>
          <div class="contain-override py-4 bg-brand-turquoise text-white">
            <h1 class="text-4xl font-serif mb-0">{{ t('Shop Unity') }}</h1>
          </div>
          <slot name="header">
            <div
              v-if="showForm"
              class="contain-override pt-10 pb-2 bg-brand-cornflower border-t-2 border-b-2 border-brand-peacock"
            >
              <SearchFormProducts
                v-model:keywords="keywords"
                @submit="onSubmit"
                @reset="onReset"
              />
            </div>
          </slot>
        </header>
        <main id="app-main" class="page-content">
          <div class="nuxt-content">
            <slot />
          </div>
        </main>
        <footer id="app-footer" class="page-footer">
          <UnityFooter />
        </footer>
      </div>
    </div>
    <ScrollToTop />
  </div>
</template>

<script setup lang="ts">
type SearchParams = {
  keywords: string
}

const { t, locale } = useI18n()
const localizePath = useLocalePath()

const route = useRoute()

const showForm = ref(false)

watch(
  () => route.name,
  () => {
    showForm.value = !String(route.name)?.startsWith('search-products')
  },
  { immediate: true },
)

const keywords = ref('')

const onSubmit = (input: SearchParams) => {
  showForm.value = false
  keywords.value = ''
  navigateTo({
    path: localizePath('/search/products'),
    query: { keywords: input.keywords },
  })
}

const onReset = () => {
  keywords.value = ''
}

useHead({
  title: t('Shop Unity'),
  htmlAttrs: {
    lang: unref(locale),
  },
})
</script>

<i18n lang="json">
{
  "en": {
    "Shop Unity": "Shop Unity"
  },
  "es": {
    "Shop Unity": "Tienda"
  }
}
</i18n>
